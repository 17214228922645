
import React from 'react'
import "../Styles/Header.scss"

const Header = () => {
  return (
    <div className='header-container'>
       <a href='/'>gorkemater.com</a>
    </div>
  )
}

export default Header